body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
  display: flex;
  min-height: 100vh;
  align-items: center;
  justify-content: center;
  background-image: linear-gradient(70deg, lightgrey, grey 48%, lightgrey);
}

.auth-form-container, .login-form, .register-form {
  display: flex;
  flex-direction: column;
}

@media screen and (min-width: 600px) {
  .auth-form-container {
    padding: 5rem;
    border: 1px solid white;
    border-radius: 10px;
  }
}

label {
  text-align: left;
  padding: 0.25rem 0;
  color: white;
}

input {
  margin: 0.5rem 0;
  padding: 1rem;
  border: none;
  border-radius: 10px;
}

button {
  border: none;
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  cursor: pointer;
}

.link-btn {
  background: none;
  color: white;
  text-decoration: underline;
}
